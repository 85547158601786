// src/app/services/auth-interceptor.service.ts
import { Injectable } from '@angular/core';
import { CapacitorHttp, HttpResponse } from '@capacitor/core';

// Internal services
import { AuthService } from '../services/auth-service/auth.service';

// Internal models
import { RefreshTokenResponse } from '../models/auth/responses/refresh-token.response';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {
  private isRefreshing = false;
  private refreshTokenPromise: Promise<string | null> | null = null;

  constructor(private authService: AuthService) { }

  /**
   * Makes an authenticated HTTP request by attaching the Access Token.
   * If a 401 Unauthorized error occurs, attempts to refresh the token and retry the request.
   */
  async makeAuthenticatedRequest(options: any): Promise<HttpResponse> {
    let accessToken = this.authService.getAccessToken();

    // current page
    const currentPage = localStorage.getItem('currentPage');

    if (accessToken) {
      options.headers = options.headers || {};
      options.headers['Authorization'] = `Bearer ${accessToken}`;
    } else {
      // check if there is a refresh token
      if (this.authService.getRefreshToken()) {
        
        // if already refreshing, wait for the existing refresh to complete
        if (this.isRefreshing && this.refreshTokenPromise) {
          accessToken = await this.refreshTokenPromise;
          
          if (!accessToken) {
            await this.authService.logout();
            throw new Error('Unable to refresh access token');
          }
        } else {
          // refresh the token
          accessToken = await this.refreshToken();

          if (!accessToken) {
            await this.authService.logout();
            throw new Error('Unable to refresh access token');
          }
        }

      } else {
        if (await this.isValidUnauthenticatedRoute(currentPage)) {
          // This is an unauthenticated route that is allowed to make the request
        } else {
          await this.authService.logout();
          throw new Error('No refresh token found');
        }
      }
    }

    try {
      const response: HttpResponse = await CapacitorHttp.request(options);

      if (response.status === 401 && this.authService.getRefreshToken()) {
        // If already refreshing, wait for the existing refresh to complete
        if (this.isRefreshing && this.refreshTokenPromise) {
          const newAccessToken = await this.refreshTokenPromise;
          if (newAccessToken) {
            options.headers['Authorization'] = `Bearer ${newAccessToken}`;
            return CapacitorHttp.request(options);
          } else {
            throw new Error('Unable to refresh access token');
          }
        }

        // Start refreshing
        const newAccessToken = await this.refreshToken();
        if (newAccessToken) {
          options.headers['Authorization'] = `Bearer ${newAccessToken}`;
          return CapacitorHttp.request(options);
        } else {
          throw new Error('Unable to refresh access token');
        }
      }

      return response;
    } catch (error) {
      throw error;
    }
  }

  async isValidUnauthenticatedRoute(route: string): Promise<boolean> {
    return route.startsWith('/super-admin') || route.startsWith('/reset-password') || route.startsWith('/forgot-password');
  }

  async refreshToken() {
    this.isRefreshing = true;
    this.refreshTokenPromise = this.authService.refreshToken()
      .then(refreshResponse => {
        this.isRefreshing = false;
        return refreshResponse.accessToken;
      })
      .catch(async (refreshError) => {
        this.isRefreshing = false;
        await this.authService.logout();
        throw refreshError;
      });

    return this.refreshTokenPromise;
  }
}
