import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Capacitor } from '@capacitor/core';
import { App, AppState } from '@capacitor/app';
import { fromEvent, merge, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppVisibilityService implements OnDestroy {
  private visibilityChangeSubject = new Subject<boolean>();
  visibilityChange$: Observable<boolean> = this.visibilityChangeSubject.asObservable();

  private appStateListener: any;

  constructor(private platform: Platform, private ngZone: NgZone) {
    this.initialize();
  }

  private initialize() {
    this.platform.ready().then(() => {
      if (this.isNative()) {
        this.setupNativeListeners();
      } else {
        this.setupWebListeners();
      }
    });
  }

  private isNative(): boolean {
    return Capacitor.isNativePlatform();
  }

  private setupWebListeners() {
    // Page Visibility API
    document.addEventListener('visibilitychange', () => {
      this.ngZone.run(() => {
        const isVisible = !document.hidden;
        this.visibilityChangeSubject.next(isVisible);
      });
    });

    // Window Focus and Blur
    window.addEventListener('focus', () => {
      this.ngZone.run(() => {
        this.visibilityChangeSubject.next(true);
      });
    });

    window.addEventListener('blur', () => {
      this.ngZone.run(() => {
        this.visibilityChangeSubject.next(false);
      });
    });
  }

  private setupNativeListeners() {
    // Capacitor App Pause and Resume
    this.appStateListener = App.addListener('appStateChange', (state: AppState) => {
      this.ngZone.run(() => {
        this.visibilityChangeSubject.next(state.isActive);
      });
    });
  }

  ngOnDestroy() {
    if (this.appStateListener) {
      this.appStateListener.remove();
    }
  }
}
