// Angular imports
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Subject } from 'rxjs';

//Internal providers
import { GlobalAppProvider } from '../app.provider';

//Internal services
import { AccountService } from '../services/account/account.service';

//Internal models
import { DefaultFloorBayObject, DefaultFloorObject } from '../models/default-floor.model';
import { DailyBayObject, DailyFloorObject } from '../models/venue.model';
import { ReservationsDto } from '../models/reservations/dtos/reservation.dto';


export interface AssignBayReservationTime {
    reservationTime: string;
    reservationEndTime: string;
}


@Injectable()
export class ReservationsProvider {
    //add default floor variables
    showDefaultFloor: boolean = false;
    showDefaultFloorObservable = new Subject<boolean>();

    //edit default floor variables
    showEditDefaultFloor: boolean = false;
    showEditDefaultFloorObservable = new Subject<boolean>();

    //default floor bay variables
    showDefaultFloorBays: boolean = false;
    showDefaultFloorBaysObservable = new Subject<boolean>();
    selectedDefaultFloor: DefaultFloorObject;
    updatingDefaultBay: DefaultFloorBayObject;

    //add default floor bay variables
    showAddDefaultFloorBay: boolean = false;
    showAddDefaultFloorBayObservable = new Subject<boolean>();

    //add default floor variables
    showUpsertDefaultFloor: boolean = false;
    showUpsertDefaultFloorObservable = new Subject<boolean>();
    updatingDefaultFloor: DefaultFloorObject;

    //add venue floor variables
    showAddFloor: boolean = false;
    showAddFloorObservable = new Subject<boolean>();

    //add venue bay variables
    showAddBay: boolean = false;
    showAddBayObservable = new Subject<boolean>();

    //manage venue bays variables
    showManageBays: boolean = false;
    showManageBaysObservable = new Subject<boolean>();

    // show reservations list variables
    showReservationsList: boolean = false;
    showReservationsListObservable = new Subject<boolean>();

    //add reservation variables
    showAddReservation: boolean = false;
    selectedAddReservationBay: DailyBayObject = null;
    showAddReservationObservable = new Subject<boolean>();

    //add waitlist reservation variables
    showAddWaitlistReservation: boolean = false;
    showAddWaitlistReservationObservable = new Subject<boolean>();

    //upload open table variables
    showOpenTableUpload: boolean = false;
    showOpenTableUploadObservable = new Subject<boolean>();

    //assign bay variables
    showAssignBay: boolean = false;
    showAssignBayObservable = new Subject<boolean>();
    assignBayReservation: ReservationsDto;
    selectedBayAssignment: DailyBayObject[] = [];
    selectedBayAssignmentObservable = new Subject<DailyBayObject>();
    assignBayReservationTime: AssignBayReservationTime;
    assignBayReservationTimeUpdatedObservable = new Subject<boolean>();
    updatedAssignBayMapFloors: DailyFloorObject[] = [];
    assignBayMapBaysUpdatedObservable = new Subject<boolean>();

    //assign bay variables
    showViewReservation: boolean = false;
    showViewReservationObservable = new Subject<boolean>();
    viewingReservation: ReservationsDto;

    //selected calendar floor variables
    selectedDailyFloor: DailyFloorObject;
    selectedDailyFloorObservable = new Subject<DailyFloorObject>();

    //selected calendar time interval variables
    selectedTimeInterval: number = 15;
    selectedTimeIntervalObservable = new Subject<number>();

    constructor(
        public modalCtrl: ModalController,
        private appProvider: GlobalAppProvider,
        private accountService: AccountService
    ) {

    }

    async showDefaultFloorSlideOut(show: boolean) {
        this.showDefaultFloor = show;
        this.showDefaultFloorObservable.next(show);
    }
    async getShowDefaultFloor() {
        return this.showDefaultFloor;
    }


    async showEditDefaultFloorSlideOut(show: boolean) {
        this.showEditDefaultFloor = show;
        this.showEditDefaultFloorObservable.next(show);
    }
    async getShowEditDefaultFloor() {
        return this.showEditDefaultFloor;
    }
    async getSelectedDefaultFloor() {
        return this.selectedDefaultFloor;
    }


    async showUpsertDefaultFloorBaySlideOut(show: boolean, selectedBay?: DefaultFloorBayObject) {
        this.showAddDefaultFloorBay = show;
        this.updatingDefaultBay = selectedBay;
        this.showAddDefaultFloorBayObservable.next(show);
    }
    async getShowAddDefaultFloorBay() {
        return this.showAddDefaultFloorBay;
    }
    async getUpdatingDefaultBay() {
        return this.updatingDefaultBay;
    }


    async showDefaultFloorBaysSlideOut(show: boolean, selectedFloor: DefaultFloorObject) {
        this.showDefaultFloorBays = show;
        this.selectedDefaultFloor = selectedFloor;
        this.showDefaultFloorBaysObservable.next(show);
    }
    async getShowDefaultFloorBays() {
        return this.showDefaultFloorBays;
    }


    async showUpsertDefaultFloorSlideOut(show: boolean, selectedFloor?: DefaultFloorObject) {
        this.showUpsertDefaultFloor = show;
        this.updatingDefaultFloor = selectedFloor;
        this.showUpsertDefaultFloorObservable.next(show);
    }
    async getShowAddDefaultFloor() {
        return this.showUpsertDefaultFloor;
    }
    async getUpdatingDefaultFloor() {
        return this.updatingDefaultFloor;
    }
    

    async showAddFloorSlideOut(show: boolean) {
        this.showAddFloor = show;
        this.showAddFloorObservable.next(show);
    }
    async getShowAddFloor() {
        return this.showAddFloor;
    }


    async showAddBaySlideOut(show: boolean) {
        this.showAddBay = show;
        this.showAddBayObservable.next(show);
    }
    async getShowAddBay() {
        return this.showAddBay;
    }


    async showManageBaysSlideOut(show: boolean) {
        this.showManageBays = show;
        this.showManageBaysObservable.next(show);
    }
    async getShowManageBays() {
        return this.showManageBays;
    }


    // show reservations list variables
    async showReservationsListSlideOut(show: boolean) {
        this.showReservationsList = show;
        this.showReservationsListObservable.next(show);
    }
    async getShowReservationsList() {
        return this.showReservationsList;
    }

    // add reservation variables
    async showAddReservationSlideOut(show: boolean, selectedBay: DailyBayObject | null) {
        this.showAddReservation = show;
        this.selectedAddReservationBay = selectedBay;
        this.showAddReservationObservable.next(show);
    }
    async getShowAddReservation() {
        return this.showAddReservation;
    }
    async getSelectedAddReservationBay() {
        return this.selectedAddReservationBay;
    }

    // add waitlist reservation variables
    async showAddWaitlistReservationSlideOut(show: boolean) {
        this.showAddWaitlistReservation = show;
        this.showAddWaitlistReservationObservable.next(show);
    }
    async getShowAddWaitlistReservation() {
        return this.showAddWaitlistReservation;
    }


    async showOpenTableUploadSlideOut(show: boolean) {
        this.showOpenTableUpload = show;
        this.showOpenTableUploadObservable.next(show);
    }
    async getShowOpenTableUpload() {
        return this.showOpenTableUpload;
    }


    async showAssignBaysSlideOut(show: boolean, reservation: ReservationsDto) {
        this.showAssignBay = show;
        this.assignBayReservation = reservation;
        this.showAssignBayObservable.next(show);
    }
    async getShowAssignBays() {
        return this.showAssignBay;
    }
    async getAssignBayReservation() {
        return this.assignBayReservation;
    }

    async assignBayReservationTimeUpdated(newTimes: AssignBayReservationTime) {
        this.assignBayReservationTime = newTimes;
        this.assignBayReservationTimeUpdatedObservable.next(true);
    }
    async getAssignBayReservationTime() {
        return this.assignBayReservationTime;
    }
    async assignBayMapBaysUpdated(bays: DailyFloorObject[]) {
        this.updatedAssignBayMapFloors = bays;
        this.assignBayMapBaysUpdatedObservable.next(true);
    }
    async getUpdatedAssignBayMapFloors() {
        return this.updatedAssignBayMapFloors;
    }

    async setSelectedBayAssignmentFromMap(selectedBay: DailyBayObject) {
        if (selectedBay.assignmentSelected) {
            this.selectedBayAssignment.push(selectedBay);
        } else {
            this.selectedBayAssignment = this.selectedBayAssignment.filter(bay => bay.dailyBayId !== selectedBay.dailyBayId);
        }

        this.selectedBayAssignmentObservable.next(selectedBay);
    }
    async getSelectedBayAssignment() {
        return this.selectedBayAssignment;
    }
    async resetSelectedBayAssignment() {
        this.selectedBayAssignment = [];
        this.selectedBayAssignmentObservable.next(null);
    }


    async showViewReservationSlideOut(show: boolean, reservation: ReservationsDto) {
        this.showViewReservation = show;
        this.viewingReservation = reservation;
        this.showViewReservationObservable.next(show);
    }
    async getShowViewReservation() {
        return this.showViewReservation;
    }
    async getViewingReservation() {
        return this.viewingReservation;
    }


    async setSelectedReservationCheckedInMaleGuests(checkedInMaleGuests: number) {
        this.viewingReservation.checkedInMaleGuests = checkedInMaleGuests;
    }
    async getSelectedReservationCheckedInMaleGuests(): Promise<number> {
        return this.viewingReservation.checkedInMaleGuests;
    }
    async setSelectedReservationCheckedInFemaleGuests(checkedInFemaleGuests: number) {
        this.viewingReservation.checkedInFemaleGuests = checkedInFemaleGuests;
    }
    async getSelectedReservationCheckedInFemaleGuests(): Promise<number> {
        return this.viewingReservation.checkedInFemaleGuests;
    }
    async setSelectedReservationCheckedInOtherGuests(checkedInOtherGuests: number) {
        this.viewingReservation.checkedInOtherGuests = checkedInOtherGuests;
    }
    async getSelectedReservationCheckedInOtherGuests(): Promise<number> {
        return this.viewingReservation.checkedInOtherGuests;
    }


    async setSelectedFloorDailyFloor(dailyFloor: DailyFloorObject) {
        this.selectedDailyFloor = dailyFloor;
        this.selectedDailyFloorObservable.next(dailyFloor);
    }
    async getSelectedDailyFloor() {
        if (!!this.selectedDailyFloor) {
            return this.selectedDailyFloor;
        }
        return null;
    }

    async setSelectedTimeInterval(interval: number) {
        this.selectedTimeInterval = interval;
        this.selectedTimeIntervalObservable.next(interval);
    }
    async getSelectedTimeInterval() {
        return this.selectedTimeInterval;
    }
}
