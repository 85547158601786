import { Injectable } from '@angular/core';
import { CapacitorHttp, HttpResponse, HttpOptions } from '@capacitor/core';
import { AuthInterceptorService } from '../interceptors/auth.interceptor';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {
  constructor(protected authInterceptor: AuthInterceptorService) {}

  protected async get<T>(endpoint: string, retry = true): Promise<T | null> {
    const options: HttpOptions = {
      method: 'GET',
      url: `${environment.baseUrl}${endpoint}`,
      headers: { 'Content-Type': 'application/json' }
    };

    return this.executeRequest<T>(options, retry);
  }

  protected async post<T>(endpoint: string, body: object, retry = true): Promise<T | null> {
    const options: HttpOptions = {
      method: 'POST',
      url: `${environment.baseUrl}${endpoint}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: body ?? {}
    };

    return this.executeRequest<T>(options, retry);
  }

  protected async put<T>(endpoint: string, body: object, retry = true): Promise<T | null> {
    const options: HttpOptions = {
      method: 'PUT',
      url: `${environment.baseUrl}${endpoint}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: body ?? {}
    };

    return this.executeRequest<T>(options, retry);
  }

  protected async delete<T>(endpoint: string, body: object, retry = true): Promise<T | null> {
    const options: HttpOptions = {
      method: 'DELETE',
      url: `${environment.baseUrl}${endpoint}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: body ?? {}
    };

    return this.executeRequest<T>(options, retry);
  }

  private async executeRequest<T>(options: HttpOptions, retry: boolean): Promise<T | null> {
    try {
      const response: HttpResponse = await this.authInterceptor.makeAuthenticatedRequest(options);

      switch(response.status) {
        case 200:
          return response.data as T;
        case 204:
          return null;
        default:
          const error = new Error(response.data?.message || 'An error occurred');
          (error as any).status = response.status; // Add status code to the error object
          throw error;
      }

      // if (response.status === 200) {
      //   return response.data as T;
      // } else if (response.status === 204) {
      //   return null;
      // } else {
      //   const error = new Error(response.data?.message || 'An error occurred');
      //   (error as any).status = response.status; // Add status code to the error object
      //   //(error as any).message = response.data.message; // Add message to the error object
      //   throw error;
      // }
    } catch (error) {
      console.error('HTTP Request Error:', error);
      throw error;
    }
  }
}
