//Angular imports
import { Injectable } from '@angular/core';
import { Observable, pipe, Subject } from 'rxjs';

//Ionic imports
import { LoadingController } from '@ionic/angular';

// Other external imports
import { DateTime } from 'luxon';

// Internal services
import { AuthService } from './services/auth-service/auth.service';
import { SignalrService } from './services/signalr-service/signalr.service';

//Internal models
import { EmployeePermissionsObject } from './models/account.model';

//Internal enums
import { ActivePage } from './enums/nav/active-page.enum';

@Injectable()
export class GlobalAppProvider {
    // global loading variables
    globalLoading: HTMLIonLoadingElement;

    // token refreshed variables
    tokenRefreshedObservable = new Subject();

    //employee permissions variables
    fetchedEmployeePermission = new Subject();
    employeePermissions: EmployeePermissionsObject;

    //event list variables
    showEventListObservable = new Subject();
    showEventListDropdown: boolean;

    //notification popOut variables
    showNotificationsObservable = new Subject();
    showNotificationsPopOut: boolean;

    //mobile sidebar variables
    showMobileSidebarObservable = new Subject();
    showMobileSidebar: boolean;

    //active page variables
    activePageObservable = new Subject();
    activePage: ActivePage = ActivePage.dashboard;

    //employee profile picture variables
    employeeProfilePictureObservable = new Subject();
    employeeProfilePicture: string;

    //app state variables
    appIsInForegroundObservable = new Subject();

    //mobile nav clicked variables
    mobileNavClickedObservable = new Subject();
    topNavClickedObservable = new Subject();

    //selected date
    selectedDateObservable = new Subject<string>();
    selectedDate: string;

    //selected reservations view
    selectedReservationsViewObservable = new Subject<string>();
    selectedReservationsView: string = 'map';


    constructor(
        private authService: AuthService,
        private loadingCtrl: LoadingController,
        private signalrService: SignalrService
    ) {

    }

    async presentGlobalLoading(message: string) {
        this.globalLoading = await this.loadingCtrl.create({
            message,
            mode:'ios',
            cssClass: 'custom-loading',
        });
        await this.globalLoading.present();
    }
    async updateGlobalLoadingMessage(message: string) {
        if (this.globalLoading) {
            this.globalLoading.message = message;
        }
    }
    async dismissGlobalLoading() {
        // check if the loading is still open
        if (this.globalLoading) {
            await this.globalLoading.dismiss();
        }
    }
    async isGlobalLoadingOpen() {
        return this.globalLoading ? true : false;
    }

    setTokenRefreshed(refreshed: boolean) {
        this.tokenRefreshedObservable.next(refreshed);

        if (this.selectedDate) {
            this.setSelectedDate(this.selectedDate);
        }
    }

    // eslint-disable-next-line
    /**
     * @param  {EmployeePermissionsObject} permissions
     * This sets the employee permissions variable and triggers the employee permission Subject so that all observables fire off
     */
    setEmployeePermissions(permissions: EmployeePermissionsObject) {
        this.employeePermissions = permissions;
        this.fetchedEmployeePermission.next(permissions);
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showDropdown
     * This sets the event list dropdown variable and triggers the event list dropdown Subject so that all observables fire off
     */
    setEventListDropdown(showDropdown: boolean) {
        this.showEventListDropdown = showDropdown;
        this.showEventListObservable.next(showDropdown);
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showPopOut
     * This sets the notifications popOut variable and triggers the notifications popOut Subject so that all observables fire off
     */
    setNotificationsPopOut(showPopOut: boolean) {
        this.showNotificationsPopOut = showPopOut;
        this.showNotificationsObservable.next(showPopOut);
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} showSidebar
     * This sets the mobile sidebar variable and triggers the mobile sidebar Subject so that all observables fire off
     */
    toggleMobileSidebar() {
        this.showMobileSidebar = !this.showMobileSidebar;
        this.showMobileSidebarObservable.next(this.showMobileSidebar);
    }

    // eslint-disable-next-line
    /**
     * @param  {string} activePage
     * This sets the mobile sidebar variable and triggers the mobile sidebar Subject so that all observables fire off
     */
    async setActivePage(activePage: ActivePage) {
        this.activePage = activePage;
        this.activePageObservable.next(activePage);
    }

    async getActivePage() {
        return this.activePage;
    }


    // eslint-disable-next-line
    /**
     * @param  {string} profilePicture
     * This sets the employee profile picture variable and calls the respective Subject so that all observables fire off
     */
    async setEmployeeProfilePicture(profilePicture: string) {
        this.employeeProfilePicture = profilePicture;
        this.employeeProfilePictureObservable.next(profilePicture);
    }
    async getEmployeeProfilePicture() {
        return this.employeeProfilePicture;
    }


    // eslint-disable-next-line
    /**
     * @param  {boolean} isActive
     * This sets the appIsInForeground Subject so that all observables fire off
     */
    async setAppForegroundStatus(isActive: boolean) {
        this.appIsInForegroundObservable.next(isActive);
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} isActive
     * This sets the mobile nav clicked Subject so that all observables fire off
     */
    async setMobileNavClicked(clicked: boolean) {
        this.mobileNavClickedObservable.next(clicked);
    }

    // eslint-disable-next-line
    /**
     * @param  {boolean} isActive
     * This sets the top nav clicked Subject so that all observables fire off
     */
    async setTopNavClicked(clicked: boolean) {
        this.topNavClickedObservable.next(clicked);
    }


    // eslint-disable-next-line
    /**
     * @param  {string} date
     * This sets the selected date variable and calls the respective Subject so that all observables fire off
     */
    async setSelectedDate(date: string) {
        // format date to 2024-10-10T00:00:00.0000000
        const formattedPreviousDate = DateTime.fromISO(this.selectedDate).toFormat('yyyy-MM-dd\'T\'00:00:00.0000000');
        const formattedNewDate = DateTime.fromISO(date).toFormat('yyyy-MM-dd\'T\'00:00:00.0000000');
        const corporationDetailId = await this.authService.getCorporationDetailId();

        if (this.selectedDate && this.selectedDate !== date) {
            await this.signalrService.leaveGroup(`${corporationDetailId}_${formattedPreviousDate}`);
        }

        if (formattedNewDate && corporationDetailId && this.selectedDate !== date) {
            await this.signalrService.joinGroup(`${corporationDetailId}_${formattedNewDate}`);
        }

        this.selectedDate = date;
        this.selectedDateObservable.next(date);
    }
    async getSelectedDate() {
        return this.selectedDate;
    }


    //selected reservations view
    async setSelectedReservationsView(view: string) {
        this.selectedReservationsView = view;
        this.selectedReservationsViewObservable.next(view);
    }
    async getSelectedReservationsView() {
        return this.selectedReservationsView;
    }

}
