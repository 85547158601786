import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';

// Capacitor imports
import { Capacitor } from '@capacitor/core';

// Ionic imports
import { Platform } from '@ionic/angular';

// Internal providers
import { AccountProvider } from './providers/account.provider';
import { GlobalAppProvider } from './app.provider';

// Internal services
import { AccountService } from './services/account/account.service';
import { AuthService } from './services/auth-service/auth.service';
import { SignalrService } from './services/signalr-service/signalr.service';

//Capacitor imports
import { StatusBar } from '@capacitor/status-bar';
import { RefreshedSessionResponse } from './models/auth/responses/refreshed-session.response';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  isInitialized = false;
  initialNavigation: string | null = null;

  constructor(
    private accountProvider: AccountProvider,
    private accountService: AccountService,
    private appProvider: GlobalAppProvider,
    private authService: AuthService,
    private platform: Platform,
    private router: Router,
    private signalrService: SignalrService
  ) {
    this.setStatusBarColor();
    this.initializeApp();

    // Listen to router events to capture initial navigation
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && !this.initialNavigation) {
        this.initialNavigation = event.url;
      }
    });
  }

  async ngOnInit() {
    await this.appProvider.presentGlobalLoading('Loading...');

    this.authService.isAuthenticated$
      .subscribe(async (isAuthenticated) => {
        if (!isAuthenticated) {
          if (await this.isValidUnauthenticatedRoute(this.initialNavigation)) {
            // set current page
            localStorage.setItem('currentPage', this.initialNavigation);
            return;
          } else {
            this.router.navigate(['/login']);
          }
        }
      });

    await this.authService.initializeAuth();
    this.isInitialized = true;

    const accessToken = this.authService.getAccessToken();

    if (accessToken && !this.authService.isAccessTokenExpired()) {
      // Optionally, navigate to home or desired route
      await this.getRefreshSessionData()
        .then(async () => {
          const currentPage = localStorage.getItem('currentPage');

          const navigateTo = currentPage ? currentPage : '/home';

          const validatedNavigateTo = await this.validateNavigateTo(navigateTo);

          await this.router.navigate([validatedNavigateTo])
            .then(async () => {
              localStorage.setItem('currentPage', validatedNavigateTo);
              this.appProvider.setTokenRefreshed(true);
            })
            .catch(async (error) => {
              await this.appProvider.dismissGlobalLoading();
              console.error(error);
            });
        })
        .catch(async (error) => {
          await this.appProvider.dismissGlobalLoading();
          console.error(error);
        })
    } else {
      // No valid access token, ensure user is on login page
      if (await this.isValidUnauthenticatedRoute(this.initialNavigation)) {
        await this.appProvider.dismissGlobalLoading();
        return;
      } else {
        await this.appProvider.dismissGlobalLoading();
        this.router.navigate(['/login']);
      }
    }
  }

  async validateNavigateTo(page: string) {
    switch(page) {
      case '/home':
        return '/home';
      case '/reservations':
        return '/reservations';
      case '/settings':
        return '/settings';
      default:
        return '/home';
    }
  }

  async isValidUnauthenticatedRoute(route: string): Promise<boolean> {
    return route.startsWith('/super-admin') || route.startsWith('/reset-password') || route.startsWith('/forgot-password') || route.startsWith('/privacy-policy') || route.startsWith('/terms-of-service') || route.startsWith('/end-user-agreement');
  }
  

  initializeApp() {
    this.platform.ready().then(() => {
      // Any platform-specific initialization can go here
      // SignalRService connection is already started in its constructor
    });

    // Listen to platform pause and resume events if needed
    this.platform.pause.subscribe(() => {
      // Handle app pause
      // this.signalrService.stopConnection();
    });

    this.platform.resume.subscribe(() => {
      // Handle app resume (optional)
      // Restart the signalr connection if it was stopped
      // this.signalrService.startConnection();
    });
  }

  async getRefreshSessionData() {
    await this.accountService.getRefreshSessionData()
      .then(async (refreshResponse: RefreshedSessionResponse) => {

        //task 6
        await Promise.all([
          this.authService.setRefreshUserTokens(refreshResponse.tokens),
          this.accountService.setUserPermissions(refreshResponse.permissions),
          this.accountService.setRefreshSessionDetails(refreshResponse),
          this.accountProvider.setSelectableCorporationDetails(refreshResponse.selectableChildCorporations)
        ]);

        //TODO move this into the login response once implemented
        // await this.accountService.getEmployeeProfilePicture()
        //   .then(async (profilePicResponse: EmployeeProfilePictureObject) => {
        //     if (profilePicResponse.hasProfilePicture) {
        //       await this.appProvider.setEmployeeProfilePicture(profilePicResponse.imageId);
        //     }
        //   })
        //   .catch(async (error) => {
        //     // eslint-disable-next-line max-len
        //     await this.presentToast('An error occurred while trying to retrieve your profile picture. Please contact customer support for further assistance', null, 'bottom', 'error-toast', 'bug');
        //   });
      })
      .catch(async (error) => {
        console.error(error);
      });
  }

  async setStatusBarColor() {
    if (this.isNative()) {
      await StatusBar.setBackgroundColor({color: '#007fac'});
    }
  };

  private isNative(): boolean {
    return Capacitor.isNativePlatform();
  }
}
